import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import type { CartButtonWidgetProps } from '../../types/cartButton';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/

const DEFAULT_DATA: CartButtonWidgetProps = {
  type: 'text',
  defaultValue: 'mini-cart',
};

export default createBlocksModel({
  widgetName: 'Cart button',
  props: {
    navigationTarget: DEFAULT_DATA,
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
