export const SPECS = {
  editCartItem: 'specs.restaurants.editCartItem',
  saveDispatchDetails: 'specs.restaurants.saveDispatchDetails',
  installationRetry: 'specs.restaurants.oloFedInstallationRetry',
  skipMemberAreaAutoInstall: 'specs.restaurants.skipMemberAreaAutoInstall',
  menuOrderPanel: 'specs.restaurants.menuOrderPanel',
  myBusinessActions: 'specs.restaurants.myBusinessActions',
  localeDatasetCurrencyFormatting: 'specs.restaurants.localeDatasetCurrencyFormatting',
  multiPages: 'specs.restaurants.oloMultiPages',
  getMenusByOperation: 'specs.restaurants.getMenusByOperation',
  openItemModalByQueryParams: 'specs.restaurants.openItemModalByQueryParams',
  floatingCartButton: 'specs.restaurants.oloCartButtonBob',
  floatingCartButtonEditor: 'specs.restaurants.oloCartButtonEditor',
  menuAvailability: 'specs.restaurants.menuAvailabilityLivesite',
  useAddressFormatter: 'specs.restaurants.useAddressFormatter',
} as const;
